import React, { useState, useEffect } from 'react'
import { Icon, Popup, Label, Menu, Segment, Button, Tab, Header, Modal, Grid} from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';

import { 
  InfoTable, 
  PageHeaderContainer, 
  FlexThis, 
  LoanPaymentTab as LoanPaymentTabStyled,
  InfoTableContainer as LoanPaymentTabsContainer, 
  POSITIVE_PASTEL_GREEN,
  LATE_PASTEL_RED,
  UPCOMING_PASTEL_YELLOW,
  InnerTableContainer
} from '../../../styled/styled'
import { GetScheduledPaymentStatusText, GetPaymentStatusText } from '../../../lib/helpers'

// the actual table inside each tab
function PaymentsInnerInfoTable({ sortedLoanPaymentsArray, paidOff }) {
  let [payments, setPayments] = useState(null)
  let [activePayment, setActivepayment] = useState(null)
  let [showMessage, setShowmessage] = useState(false)
  const today = Date.now()
  useEffect(() => {
    // remove any duplicate payments, this happens on the server side and i'm not sure why.
    if (!payments && sortedLoanPaymentsArray) {
      let _sortedLoanPaymentsArray = sortedLoanPaymentsArray.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
	  let datedLoanPaymentsArray = _sortedLoanPaymentsArray.map(p => ({...p, paymentDueDate: new Date(p.paymentDueOn)}))
	  let latePayments = datedLoanPaymentsArray.filter(p => GetPaymentStatusText(p.paymentStatus) !== "Paid").filter(p => p.paymentDueDate < today)
	  if(latePayments.length > 0){
		  let activeId = latePayments.reduce( (start, p) => start.paymentDueDate <= p.paymentDueDate ? start : p, latePayments[0]).id
		  setActivepayment(activeId)
	  }
      setPayments(_sortedLoanPaymentsArray)
	  
    }
  }, [sortedLoanPaymentsArray])

  return (
    <InnerTableContainer>
      {/* OUTSTANDING LOAN PAYMENTS */}
      <PageHeaderContainer style={{ fontSize: 20, marginBottom: 5 }}>Outstanding Loan Payments</PageHeaderContainer>
		<Grid colums={6} >
			<Grid.Row className='grid'>
				<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
					Payment Due
				</Grid.Column>
				<Grid.Column style={{width: '15%'}}>
					Name
				</Grid.Column>
				<Grid.Column style={{width: '16%'}}>
					Monthly Payment ($)
				</Grid.Column>
				<Grid.Column style={{width: '16%'}}>
					Amount Paid ($)
				</Grid.Column>
				<Grid.Column style={{width: '16%'}}>
					Remaining Balance ($)
				</Grid.Column>
				<Grid.Column style={{width: '16%'}}>
					Actions
				</Grid.Column>
			</Grid.Row>
		</Grid> 
		{
			payments && payments.map((loanPayment, i) => {
			let dueDate = new Date(loanPayment.paymentDueOn)
			let isPaid = GetPaymentStatusText(loanPayment.paymentStatus) === "Paid"
			let isUnscheduled = GetScheduledPaymentStatusText(loanPayment.scheduledPaymentStatus) === "Unscheduled"
			let isLate = dueDate < Date.now() && !isPaid
			if (!isPaid){
				return <Grid colums={6}>
					<Grid.Row style={{ backgroundColor: isPaid ? POSITIVE_PASTEL_GREEN : isLate ? LATE_PASTEL_RED : UPCOMING_PASTEL_YELLOW, margin: 'auto', height: '50px', }} key={i}>
						<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
						{isPaid ?
						<Popup 
						basic 
						style={{ border: '0.5px solid black' }}
						content='Paid'
						trigger={
							<Icon name='calendar check' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18, color: 'green' }} />
						} />
						:
						isLate ?
						<Popup 
						basic
						style={{ border: '0.5px solid black' }}
						content='Late'
						trigger={
							<Icon name='calendar times' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18, color: 'red' }} />
						} />
						:
						<Popup 
						basic
						style={{ border: '0.5px solid black' }}
						content='Upcoming'
						trigger={
							<Icon name='calendar outline' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18 }} />
						} />}
						{moment.utc(dueDate).format('MM/DD/YYYY')} 
						</Grid.Column>
						<Grid.Column style={{width: '15%'}}>
							{loanPayment.name}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.monthlyPayment).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.totalAmountPaid).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.amountDue).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{!paidOff && <FlexThis style={{position: 'relative', top: '-8px'}}>
								<Popup 
								content='Go to draw details'
								trigger={
									<Button 
									as='a'
									href={`https://payment-link.azurewebsites.net/p/${loanPayment.shortId}`}
									target='_blank'
									icon
									primary
									fluid
									labelPosition='right'>Pay <Icon name='dollar sign' /></Button>
								} />
							</FlexThis>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			})
		}
	  
      {/* PAID LOAN PAYMENTS */}
		<PageHeaderContainer style={{ fontSize: 20, marginBottom: 5, marginTop: 10 }}>Paid Loan Payments</PageHeaderContainer>
			<Grid colums={6} >
				<Grid.Row className='grid'>
					<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
						Payment Due
					</Grid.Column>
					<Grid.Column style={{width: '15%'}}>
						Name
					</Grid.Column>
					<Grid.Column style={{width: '16%'}}>
						Monthly Payment ($)
					</Grid.Column>
					<Grid.Column style={{width: '16%'}}>
						Amount Paid ($)
					</Grid.Column>
					<Grid.Column style={{width: '16%'}}>
						Remaining Balance ($)
					</Grid.Column>
					<Grid.Column style={{width: '16%'}}>
						Actions
					</Grid.Column>
				</Grid.Row>
			</Grid> 
			{
			payments && payments.map((loanPayment, i) => {
			let dueDate = new Date(loanPayment.paymentDueOn)

          
			let isPaid = GetPaymentStatusText(loanPayment.paymentStatus) === "Paid"
			let isUnscheduled = GetScheduledPaymentStatusText(loanPayment.scheduledPaymentStatus) === "Unscheduled"
			let isLate = dueDate < Date.now() && !isPaid
			if (isPaid){
				return <Grid colums={6}>
					<Grid.Row style={{ backgroundColor: isPaid ? POSITIVE_PASTEL_GREEN : isLate ? LATE_PASTEL_RED : UPCOMING_PASTEL_YELLOW, margin: 'auto', height: '50px', }} key={i}>
						<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
						{isPaid ?
						<Popup 
						basic 
						style={{ border: '0.5px solid black' }}
						content='Paid'
						trigger={
							<Icon name='calendar check' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18, color: 'green' }} />
						} />
						:
						isLate ?
						<Popup 
						basic
						style={{ border: '0.5px solid black' }}
						content='Late'
						trigger={
							<Icon name='calendar times' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18, color: 'red' }} />
						} />
						:
						<Popup 
						basic
						style={{ border: '0.5px solid black' }}
						content='Upcoming'
						trigger={
							<Icon name='calendar outline' style={{ marginLeft: 5, marginRight: 5, fontWeight: 'bold', fontSize: 18 }} />
						} />}
						{moment.utc(dueDate).format('MM/DD/YYYY')} 
						</Grid.Column>
						<Grid.Column style={{width: '15%'}}>
							{loanPayment.name}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.monthlyPayment).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.totalAmountPaid).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							{numeral(loanPayment.amountDue).format('0,0.00')}
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							<FlexThis style={{position: 'relative', top: '-8px'}}>
								<Popup 
								content='Go to draw details'
								trigger={
									<Button 
									as='a'
									href={`https://payment-link.azurewebsites.net/p/${loanPayment.shortId}`}
									target='_blank'
									icon
									primary
									fluid
									labelPosition='right'>Details <Icon name='arrow right' /></Button>
								} />
							</FlexThis>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			})
		}
      
	  <Modal
		style={{height: '200px', position: 'absolute', left: '50%', top: '35%', transform: 'translate(-50%, 0%)'}}
		closeIcon
		open={showMessage}
		size='small'
		onClose={() => setShowmessage(false)}
		onOpen={() => setShowmessage(true)}
	  >
			<Header icon='mail' content='For More Information' />
			<Modal.Content>
				<p>
					Please reach out to our servicing department at Servicing@WCP.Teams for more information
				</p>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => setShowmessage(false)} positive>Ok</Button>
			</Modal.Actions>
	  </Modal>
    </InnerTableContainer>
  )
}

export default PaymentsInnerInfoTable