import React, { useState, useEffect } from 'react'
import { Label, Grid } from 'semantic-ui-react'
import numeral from 'numeral'
import moment from 'moment'
import { GetLoanStatusText } from '../../../lib/helpers'
import { 
  LoanPaymentTab as LoanPaymentTabStyled, 
  LoanPaymentTabTitle, 
  InfoTableContainer as LoanPaymentTabsContainer, 
} from '../../../styled/styled'
import { sortDatesDesc, sortDatesAsc } from '../../../lib/helpers'
import LoanInformationCard from '../LoanInformationCard';
import PaymentsInnerInfoTable from './PaymentsInnerInfoTable';
import { useGlobalData } from '../../../contexts/GlobalData';

/**
 * 
 * @param {object} tabData - the loan object contains all things related to it, (like loan payments) 
 */
function LoanPaymentTab({ tabData }) {
  const { loansInfoMap } = useGlobalData();
  const [showTable, setShowTable] = useState(false)
  const [ labels, setLabels ] = useState({
    unscheduled: false,
    unscheduledCount: null,
    paid: false,
    paidCount: null,
    late: false,
    lateCount: null
  })

  const [labelsSet, setLabelSet] = useState(false)
  const btn_grid = {
		paddingLeft: '25px',
		cursor: 'pointer',
		borderRadius: '10px',
		border: '1px solid black'
	}
  // how should the payment tables be sorted? date_asc, date_desc
  const [sortType, setSortType] = useState('date_desc')
  // sortedPaymentsArray, defaults to date_desc
  const [sortedLoanPaymentsArray, setSortedLoanPaymentsArray] = useState(null)

  // set labels
  useEffect(() => {
    if (tabData && loansInfoMap && !labelsSet && loansInfoMap[tabData.id]) {
      setLabels({
        unscheduled: loansInfoMap[tabData.id].hasUnscheduledPayments || false,
        unscheduledCount: loansInfoMap[tabData.id].unscheduledPaymentsCount || null,
        paid: loansInfoMap[tabData.id].hasPaidPayments || false,
        paidCount: loansInfoMap[tabData.id].paidPaymentsCount || null,
        late: loansInfoMap[tabData.id].hasLatePayments || false,
        lateCount: loansInfoMap[tabData.id].latePaymentsCount || null
      })
    }
  }, [loansInfoMap])

  // set sorted array
  useEffect(() => {
    if (!sortedLoanPaymentsArray) {
      sortLoanPayments()
    }
    console.log("sorted payments....")
    console.log(sortedLoanPaymentsArray)
  }, [sortedLoanPaymentsArray])

  function sortLoanPayments() {
    switch(sortType) {
      case 'date_asc':
        return setSortedLoanPaymentsArray(tabData.LoanPayments.sort((a, b) => sortDatesAsc(a.paymentDueOn, b.paymentDueOn)))
      default:
        return setSortedLoanPaymentsArray(tabData.LoanPayments.sort((a, b) => sortDatesDesc(a.paymentDueOn, b.paymentDueOn)))
    }
  }

  return(
	<div onClick={() => setShowTable(!showTable)}>
		<Grid style={btn_grid} >
			<Grid.Row>
				<Grid.Column width={12}>{tabData.propertyAddress}</Grid.Column>
				<Grid.Column width={4} style={{margin: '0', display: 'flex', textAlign: 'center'}}>
					{labels && labels.late &&
						<Label style={{width: '50%'}} color='red'>
							<div>Late ({labels.lateCount || 0})</div>
						</Label>
					}
				    {labels && labels.unscheduled &&
						<Label style={{width: '50%', textAlign: 'center'}} color='yellow' horizontal>
							Upcoming ({labels.unscheduledCount || 0})
						</Label>
					}
				</Grid.Column>
			</Grid.Row>
			{showTable &&
				<>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Loan Name:</Grid.Column>
					<Grid.Column width={4}>{tabData.name}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Current Status:</Grid.Column>
					<Grid.Column width={4}>{tabData.statusCode ? GetLoanStatusText(tabData.statusCode) : 'N/A'}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Loan Amount:</Grid.Column>
					<Grid.Column width={4}>${numeral(tabData.WCPLoanAmount).format('0,0.00')}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Interest Rate:</Grid.Column>
					<Grid.Column width={4}>{tabData.interestRate}%</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Current Construction Balance:</Grid.Column>
					<Grid.Column width={4}>${numeral(tabData.availableFunds).format('0,0.00')}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Construction Disbursed To Date:</Grid.Column>
					<Grid.Column width={4}>${numeral(tabData.constructionFundAmount - tabData.constructionFundBalance).format('0,0.00')}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0'}}>
					<Grid.Column width={12}>Maturity Date:</Grid.Column>
					<Grid.Column width={4}>{moment.utc(tabData.maturityDate).format('MM/DD/YYYY')}</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop: '0', paddingBottom: '0', marginBottom: '10px'}}>
					<Grid.Column width={12}>Loan Servicer:</Grid.Column>
					<Grid.Column width={4}>{tabData.loanServicer || 'N/A'}</Grid.Column>
				</Grid.Row>
				</>
			}
		</Grid>
		{showTable && <PaymentsInnerInfoTable sortedLoanPaymentsArray={sortedLoanPaymentsArray} paidOff={tabData.payOffReceived} />}
	</div>
    
  )
}

export default LoanPaymentTab